body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.form-container {
  width: 70%;
  /* height: 800px; */
  margin: auto;
  /* background: #451526; */
  color: #14121F;
  display: flex;
  flex-direction: column;
  border-radius: 20px;
  /* margin-top: 20px; */
}

.form-form {
  display: grid;
  width: 100%;
  justify-content: center;
  font-size: 1.3rem;
}

.ticket-form {
  display: grid;
  grid-row-gap: 1em;
  width: 100%;
  justify-content: center;
  font-size: 1.3rem;
}

.form-label {
  padding-top: 20px;
}

.form-field {
  border-radius: 20px;
  height: 30px;
  padding-left: 20px;
}

.hd-img {
  background-image: url('./images/nathan-anderson-roZgc7SXXmI-unsplash.jpg');
  background-size: cover;
  background-position: center center;
  position: relative;
  top: 0;
  bottom: 0;
  height: 100vh;
  z-index: 1;
}

/* .hero-overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  z-index: -5;
  background-color: rgba(0,0,0,0.25);
} */